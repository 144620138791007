import { call, put, takeLatest } from "redux-saga/effects";
import * as urls from "__utils/constants";
import { ResponseGeneratorType } from "__utils/types";
import * as api from "../__api";
import * as actionTypes from "./actionTypes";

const verifyAuth = function* ({ payload }: any): any {
	try {
		const { referer } = payload;
		const response: ResponseGeneratorType = yield call(api.verifyUserAuthApi);
		const { status, data } = response;
		if (status === 200) {
			yield put({
				type: actionTypes.VERIFY_AUTH_SUCCESS,
				data,
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(`${urls.SIGN_IN_URL}?next=${referer}`);
		} else {
			yield put({ type: actionTypes.VERIFY_AUTH_FAILURE });
		}
	} catch (error) {
		yield put({ type: actionTypes.VERIFY_AUTH_FAILURE });
	}
};

export const verifyAuthSaga = function* (): any {
	yield takeLatest(actionTypes.VERIFY_AUTH, verifyAuth);
};

const getProfile = function* (): any {
	try {
		const response: ResponseGeneratorType = yield call(api.getUserProfileApi);
		const { status, data } = response;

		if (status === 200) {
			yield put({
				type: actionTypes.GET_PROFILE_SUCCESS,
				data,
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(urls.SIGN_IN_URL);
		} else {
			yield put({ type: actionTypes.GET_PROFILE_FAILURE });
		}
	} catch (error) {
		yield put({ type: actionTypes.GET_PROFILE_FAILURE });
	}
};

export const getProfileSaga = function* (): any {
	yield takeLatest(actionTypes.GET_PROFILE, getProfile);
};

const editProfile = function* (action: any): any {
	const { payload } = action;
	try {
		const response: ResponseGeneratorType = yield call(api.updateUserProfileApi, payload);
		const { status, data } = response;

		if (status === 200) {
			yield put({
				type: actionTypes.GET_PROFILE_SUCCESS,
				data,
			});

			yield put({
				type: actionTypes.EDIT_PROFILE_SUCCESS,
			});
		} else if (status === 302 || status === 403) {
			window.location.assign(urls.SIGN_IN_URL);
		} else {
			yield put({ type: actionTypes.EDIT_PROFILE_FAILURE });
		}
	} catch (error) {
		yield put({ type: actionTypes.EDIT_PROFILE_FAILURE });
	}
};

export const editProfileSaga = function* (): any {
	yield takeLatest(actionTypes.EDIT_PROFILE, editProfile);
};
